var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['card',{'card--type-base' : _vm.type === 'base'},
												{'card--size-xlarge' : _vm.size === 'xlarge'},
												{'card--size-large' : _vm.size === 'large'},
												{'card--size-small' : _vm.size === 'small'},
												{'card--size-xsmall' : _vm.size === 'xsmall'},
												{'card--fill' : _vm.fill},
												{'card--has-image' : _vm.image},
												{'card--overlap' : _vm.overlap},
												{'card--overlapped' : _vm.overlapped},
												{'card--margin-none' : _vm.margin === 'none'},
												{'card--color-primary' : _vm.color === 'primary'},
												{'card--color-secondary' : _vm.color === 'secondary'},
												{'card--color-tertiary' : _vm.color === 'tertiary'},
												{'card--color-soft-primary' : _vm.color === 'soft-primary'},
												{'card--color-soft-secondary' : _vm.color === 'soft-secondary'},
												{'card--color-soft-tertiary' : _vm.color === 'soft-tertiary'},
												{'card--color-light' : _vm.color === 'light'},
												{'card--color-dark' : _vm.color === 'dark'}]},[(_vm.image)?_c('div',{staticClass:"card__image-background"},[_c('Images',{attrs:{"width":"100%","height":"100%","image":_vm.image}})],1):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }