<template>
  <div :class="['card',{'card--type-base' : type === 'base'},
												{'card--size-xlarge' : size === 'xlarge'},
												{'card--size-large' : size === 'large'},
												{'card--size-small' : size === 'small'},
												{'card--size-xsmall' : size === 'xsmall'},
												{'card--fill' : fill},
												{'card--has-image' : image},
												{'card--overlap' : overlap},
												{'card--overlapped' : overlapped},
												{'card--margin-none' : margin === 'none'},
												{'card--color-primary' : color === 'primary'},
												{'card--color-secondary' : color === 'secondary'},
												{'card--color-tertiary' : color === 'tertiary'},
												{'card--color-soft-primary' : color === 'soft-primary'},
												{'card--color-soft-secondary' : color === 'soft-secondary'},
												{'card--color-soft-tertiary' : color === 'soft-tertiary'},
												{'card--color-light' : color === 'light'},
												{'card--color-dark' : color === 'dark'}]">
		<div class="card__image-background" v-if="image">
			<Images width="100%" height="100%" :image="image" />
		</div>
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		type: String,
		size: String,
		color: String,
		margin: String,
		fill: Boolean,
		image: String,
		overlap: Boolean,
		overlapped: Boolean,
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/breakpoints.scss';

.card{
	width: 100%;
	position: relative;
	z-index: 7;
	background: var(--card-background-color);
	box-shadow: var(--card-box-shadow);
	border-radius: var(--card-border-radius);
	margin-bottom: var(--card-mobile-margin-bottom);
	@include breakpoint(sm){
		margin-bottom: var(--card-margin-bottom);
	}
}
.card--has-image{
	overflow: hidden;
}
.card--link{
	display: block;
	text-decoration: none;
	border: var(--card-link-border);
	transition: var(--card-link-transition);
}
.card--link:hover{
	border: var(--card-link-hover-border);
}
.card--type-base{
	background: none;
	box-shadow: none;
	border-radius: 0;
}
.card--fill{
	height: 100%;
}
.card--overlap{
	width: var(--card-overlap-width);
	
	@include breakpoint(sm){
		margin: var(--card-overlap-margin);
	}
}
.card--overlapped{
	padding-bottom: var(--card-overlapped-padding);
}
.card--margin-none{
	margin-bottom: 0;
}

.card--color-primary{
	background:  var(--card-color-primary-background);
}
.card--color-secondary{
	background:  var(--card-color-secondary-background);
}
.card--color-tertiary{
	background:  var(--card-color-tertiary-background);
}
.card--color-soft-primary{
	background:  var(--card-color-soft-primary-background);
}
.card--color-soft-secondary{
	background:  var(--card-color-soft-secondary-background);
}
.card--color-soft-tertiary{
	background:  var(--card-color-soft-tertiary-background);
}
.card--color-info{
	background:  var(--card-color-info-background);
}
.card--color-success{
	background:  var(--card-color-success-background);
}
.card--color-warning{
	background:  var(--card-color-warning-background);
}
.card--color-danger{
	background:  var(--card-color-danger-background);
}
.card--color-light{
	background:  var(--card-color-light-background);
}
.card--color-dark{
	background:  var(--card-color-dark-background);
}
.card__image-background{
	position: absolute;
	min-height: 100%;
	min-width: 100%;
	top: 0;
	left:0;
	z-index: -1;
}
</style>

<!-- Used in : AR, DS -->